/*
headergreen: #BEBC48
purple: #570946
*/

html {
  overflow-x: hidden;
}

body {
  font-family: magnat;
}

a {
  text-decoration: none;
}

.App {
  text-align: center;
  color: #570946;
}

/*HEADER*/

.header {
  background-color: #bebc48;
  height: 15vh;
  width: 100vw;
  display: flex;
  min-height: 100px;
}

.nav-desktop {
  margin-top: 4.5vh;
}

.home,
.about,
.offer,
.contact,
.references {
  width: 100vw;
  display: flex;
}

.header-left,
.header-right {
  height: 100%;
  width: 50vw;
}

.header-left {
  text-align: left;
  display: flex;
  align-items: center;
}

.logo {
  width: 35%;
  margin-top: 2%;
  margin-left: 5%;
  min-width: 180px;
}

.logo-bully {
  width: 15%;
  margin-left: -2%;
  margin-top: -3%;
  min-width: 70px;
}

.nav-tgl {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  z-index: 100;
  right: 20px;
  top: 30px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 50%;
  padding: 0;
  background: transparent;
  /* box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.24); */
  line-height: 0.6;
  text-align: center;
}

.nav-tgl > span {
  display: inline-block;
  position: relative;
  height: 2px;
  width: 34px;
  border-radius: 1px;
  background: #6f1952;
  vertical-align: middle;
}

.nav-tgl > span:before, .nav-tgl > span:after {
  display: inline-block;
  position: absolute;
  content: "";
  height: 2px;
  border-radius: 1px;
  background: #6f1952;
  transition: all 200ms;
}

.nav-tgl > span:before {
  top: -11px;
  left: 3px;
  width: 28px;
}

.nav-tgl > span:after {
  top: 11px;
  left: 6px;
  width: 22px;
}

.nav-tgl:focus {
  outline: none;
}

.nav-tgl:hover > span:after, .nav-tgl:hover > span:before {
  width: 34px;
  left: 0;
}

.nav-tgl.toggled > span {
  height: 0;
}

.nav-tgl.toggled > span:after, .nav-tgl.toggled > span:before {
  top: 0px;
  left: 0;
  width: 34px;
}

.nav-tgl.toggled > span:after {
  transform: rotate(-45deg);
}

.nav-tgl.toggled > span:before {
  transform: rotate(45deg);
}

.nav:before {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100vw;
  height: 100vh;
  background: rgba(190, 188, 72, 1);
  transition: all 500ms ease-in-out;
  clip-path: circle(30px at calc(100% - 65px) 65px);
  visibility: hidden;
  z-index: 3;
}

.active.nav:before {
  visibility: visible;
  display: block;
  clip-path: circle(100%);
}

.nav ul {
  display: none;
}

.display-block ul {
  display: flex;
  z-index: 1;
  flex-direction: column;
  list-style: none;
  font-size: 50px;
  padding: unset;
  position: fixed;
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 4;
  left: 0%;
}

/*Makes Burger only visible 700px or less*/

@media (min-width: 701px) {
  .nav-tgl {
    display: none;
  }

  .nav:before {
    display: none;
  }
}

@media (max-width: 700px) {
  .nav-desktop {
    display: none;
  }
}

/*HOME*/

.home {
  height: 85vh;
}

.home-left {
  height: 100%;
  width: 50vw;
  overflow-x: hidden;
}

.home-left::-webkit-scrollbar { 
  width: 0 !important 
}

.text-container {
  width: 70%;
  margin-left: 18%;
}

.anliegen {
  margin: 0 auto;
  font-size: 20px;
  margin-top: 20px;
}

.home-left h2 {
  margin-top: 40px;
  font-size: 30px;
  font-weight: 100;
  line-height: 1.5;
}

.home-left h3 {
  font-size: 20px;
  margin-top: -10px;
  margin-bottom: 40px;
  line-height: 1.5;
  font-weight: 100;
}

.introduction {
  text-align: left;
  font-size: 20px;
  line-height: 1.5;
}

.home-right {
  min-height: 100%;
  width: 50vw;
  background: url("./img/portrait1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.new-book {
  width: 90vw;
  padding: 5vw;
  display: flex;
  background-color: #f1f3cc;
}

.new-book h2 {
  font-size: 30px;
  font-weight: 100;
  line-height: 1.5;
}

.new-book .new-book-left, .new-book-right {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-book-left {
  margin-right: 10%;
}

nav {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 2vh;
  font-size: 30px;
  font-weight: 100;
}

.header a {
  text-decoration: none;
  color: #6f1952;
}

.door-img {
  width: 35%;
  height: 36%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-top: 10px;
}

.contact-door {
  display: flex;
  width: 50%;
  margin: 5% 0 5% 18%;
}

.contact-button {
  background-color: #6f1952;
  color: white;
  width: 12vw;
  height: 4.5vw;
  font-size: 22px;
  display: flex;
  font-weight: 100;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contact-button a {
  color: white;
  line-height: 1.5;
}

.signature {
  margin-top: -10px;
  margin-bottom: 30px;
}

.portrait1 {
  display: none;
}

/*Home Media Queries*/

@media (min-width: 1391px) and (max-width: 1620px) {
  .text-container {
    width: 70%;
    margin-left: 20%;
  }
}

@media (min-width: 1216px) and (max-width: 1390px) {
  .text-container {
    width: 80%;
    margin-left: 10%;
  }
}

@media (min-width: 1166px) and (max-width: 1215px) {

  .text-container {
    width: 80%;
    margin-left: 10%;
  }
  
  .contact-button {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1165px) {

  .text-container {
    width: 82%;
    margin-left: 10%;
  }

  .contact-button {
    font-size: 16px;
  }
 
}

@media (max-width: 1000px) {
  .text-container h2 {
    margin-top: 20px;
  }

  .contact-button {
    font-size: 16px;
    margin-bottom: 10px;
    width: 15vw;
    height: 6vw;
  }

  .home {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: unset;
  }

  .new-book .new-book-left, .new-book-right {
    width: 100%;
    align-items: center;
  }

  .new-book {
    flex-direction: column;
  }
  
  .home-left {
    width: 100vw;
    overflow-x: unset;
  }

  .home-right {
    min-height: 100%;
    width: 100vw;
    background: none;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .portrait1 {
    display: block;
    width: 70vw;
    margin: 0 auto;
    border-radius: 8px;
    margin-bottom: 30px;
  }
}

@media (max-width: 700px) {
  .contact-button {
    font-size: 16px;
    margin-bottom: 10px;
    width: 150px;
    height: 50px;
  }
}

/*OFFER Component*/

.offer {
  display: flex;
  flex-direction: column;
}

.offer h1{
  font-weight: 100;
}

.offer-intro {
  width: 50vw;
  font-size: 20px;
  line-height: 1.5;
  margin: 0 auto;
  text-align: left;
}

.offer-outro {
  width: 75vw;
  margin-left: 25vw;
  font-size: 20px;
  line-height: 1.5;
  display: flex;
}

.offer-outro-text {
  width: 50vw;
  font-size: 20px;
  line-height: 1.5;
  text-align: left;
}

.offer-points {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.5;
}

.brain-button-div {
  width: 17vw;
  margin-top: -10%;
}

.img-brain {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  float: right;
  margin-right: 8%;
  margin-bottom: -4%;
}

.coaching-button {
  background-color: #6f1952;
  color: white;
  width: 17vw;
  height: 55px;
  font-size: 25px;
  display: flex;
  font-weight: 100;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.green-boxes-container {
  display: flex;
  justify-content: space-between;
  width: 98vw;
  flex-wrap: wrap;
  margin: 40px auto;
}

.greenbox {
  background-color: #f1f3cc;
  width: 15%;
  text-align: left;
  margin-bottom: 20px;
}

.box-title {
  font-size: 22px;
  margin-left: 5%;
  font-weight: 100;
}

.box-text {
  font-size: 20px;
  line-height: 1.5;
}

.box-text-container {
  width: 90%;
  margin: 0 auto;
}


/*Offer Media Queries*/

@media (min-width: 1351px) and (max-width: 1600px) {
  .coaching-button {
    width: 17vw;
    height: 55px;
    font-size: 22px;
  }
}

@media (max-width: 1350px) {
  .coaching-button {
    height: 65px;
  }

  .greenbox {
    background-color: #f1f3cc;
    width: 31%;
    text-align: left;
    margin-bottom: 20px;
  }

  .green-boxes-container {
    width: 95vw;
  }
}


@media (max-width: 900px) {
  .offer-outro {
    flex-direction: column;
    margin: 0 auto;
    width: 100vw;
  }

  .offer-outro-text {
    margin: 0 auto;
  }

  .brain-button-div {
    width: 25vw;
    display: flex;
    margin: 0 auto;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
  }
  
  .img-brain {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .coaching-button {
    font-size: 22px;
  }
}

@media (max-width: 780px) {
  .brain-button-div {
    flex-direction: column;
  }
  .coaching-button {
    width: 35vw;
    height: 5vw;
    font-size: 20px;
  }
}



@media (max-width: 700px) {
  .greenbox {
    background-color: #f1f3cc;
    width: 45%;
    text-align: left;
    margin-bottom: 20px;
  }

  .green-boxes-container {
    width: 85vw;
  }

  .coaching-button {
    width: 50vw;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .greenbox {
    background-color: #f1f3cc;
    width: 80%;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .green-boxes-container {
    width: 100vw;
    margin-bottom: 50px;
  }
  
}

/*POPUP*/

.about {
  background-color: rgb(32, 32, 32);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.popup {
    background-color: rgb(0, 0, 0, 0.3);
    position: fixed;  
    width: 100vw;  
    height: 100vh;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
  width: 30vw;
  height: 80vh;
  background-color: white;
  border-radius: 8px;
  overflow-x: scroll;
}

.close-modal {
  cursor: pointer;
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}

.popup-text {
  margin: 0 auto;
  width: 80%;
  margin-top: 25%;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.book-hover:hover {
  cursor: pointer;
}

/*Über mich*/

.about-intro {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  text-align: left;
  font-size: 20px;
  line-height: 1.5;
  align-items: flex-start;
}

.aboutme h1 {
  font-weight: 100;
}

#more-coaching {
  background-color: #570946;
  display: block;
  width: 500px;
  padding: 8px;
  color: white;
  margin-left: 9.6vw;
}

#more-coaching a {
  color: white;
  text-decoration: none;
}

.aboutme-lower {
  width: 98vw;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  height: 60vh;
  margin-bottom: 30px;
}

.aboutme-lower-left {
  width: 40%;
  height: 100%;
}

.aboutme-lower-right {
  width: 59%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.aboutme-lower-right1 {
  width: 100%;
  background-color: #bebc48;
  margin-bottom: 15px;
}

.aboutme-lower-right2 {
  background-color: #f1f3cc;
  margin-bottom:15px;
}

.aboutme-lower-right3 {
  background-color: #bebc48;
  margin-bottom: 15px;
}

.aboutme-bulletpoint-heading {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 100;
}

.vita {
  text-align: left;
  width: 95%;
  margin: 0 auto;
  font-weight: 100;
}

.year-vita {
  min-width: 100px;
}

.year-vita-last {
  margin-bottom: 20px;
}

.vita-point {
  display: flex;
}

.thousandpx-img {
  width: 39.5vw;
  height: 28vw;
  margin: 0 auto;
  margin-bottom: 40px;
}

/*About me Media Queries*/

@media (max-width: 1000px) {
  .about-intro {
    display: flex;
    flex-direction: column;
    width: 80vw;
    align-items: center;
  }

  #more-coaching {
    margin: 0 auto;
    margin-top: 30px;
  }

  .aboutme-lower {
    flex-direction: column;
  }

  .aboutme-lower-left {
    background: unset;
    display: flex;
    width: 100vw;
  }

  .aboutme-lower-right {
    width: 90vw;
    margin: 0 auto;
  }

  .thousandpx-img {
    width: 60vw;
    height: 42vw;
    border-radius: 10px;
  }
}

@media (max-width: 780px) {
  .thousandpx-img {
    width: 60vw;
    height: 42vw;
    border-radius: 10px;
  }
}

@media (max-width: 600px) {
  .thousandpx-img {
    width: 400px;
    height: 280px;
  }

  #more-coaching {
    width: 400px;
    padding: 8px;
    color: white;
  }
}

@media (max-width: 450px) {
  
  #more-coaching {
    width: 320px;
  }

  .thousandpx-img {
    width: 350px;
    height: 246px;
  }
}

@supports (-webkit-touch-callout: none) {
  .aboutme-lower-left {
    margin-bottom: 220px;
  }
}

/*REFERENZEN*/

.references {
  display: flex;
  flex-direction: column;
}

.books-container {
  height: 45vh;
  width: 100vw;
  background-color: #570946;
  display: flex;
  color: #f1f3cc;
}

.title-container {
  margin-right: 5%;
  margin-bottom: 3%;
}

.book-titles {
  width: 55vw;
  height: 100%;
  font-size: 26px;
  line-height: 1.5;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.book-img {
  width: 30vw;
  display: flex;
  align-items: flex-end;
}

.book-img img {
  margin-bottom: 10px;
}

.book-heading {
  width: 15vw;
  height: 100%;
  font-size: 30px;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 20px;
}

.book-img-title {
  display: none;
}

.year-book {
  min-width: 110px;
}

.person-references {
  color: #570946;
  width: 100vw;
  height: 40vh;
}

.person-references h3 {
  font-size: 30px;
  font-weight: 100;
}

.person-references-heading {
  display: flex;
  justify-content: center;
  margin-left: 5vw;
}

.person-references-heading img {
  margin-left: 30px;
}

.references-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95vw;
  margin: 0 auto;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.single-reference {
  width: 30%;
  text-align: left;
  font-size: 20px;
  line-height: 1.5;
  min-height: 100%;
}

.single-reference:nth-child(2) {
  background-color: #f1f3cc;
  padding: 10px;
  padding-bottom: 30px;
}

.character-drawings {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  min-width: 125px;
}

/*REFERENZEN MEDIA QUERIES*/

@media (max-width: 1450px) {
  .books-container {
    height: 35vh;
    min-height: 300px;
  }
  .book-titles {
    width: 50vw;
  }
  .book-img {
    width: 30vw;
  }
  .book-heading {
    width: 20vw;
  }
}

@media (max-width: 1200px) {
  .books-container {
    height: 30vh;
    min-height: 250px;
  }

  .popup-inner {
    width: 35vw;
  }
}

@media (max-width: 1101px) {
  .book-titles {
    font-size: 22px;
    line-height: 1.5;
  }

  .book-heading {
    font-size: 25px;
  }

  .popup-inner {
    width: 40vw;
  }
}

@media (max-width: 1000px) {
  .book-img-title {
    display: unset;
    font-size: 25px;
  }

  .book-img {
    display: flex;
    flex-direction: column;
    width: 30vw;
    justify-content: center;
    margin-left: 6vw;
  }

  .book-titles {
    width: 60vw;
  }

  .book-heading {
    display: none;
  }

  .book-img {
    height: unset;
    display: flex;
    align-items: flex-end;
  }
  
  .book-img img {
    margin: 0;
    margin-bottom: 8%;
  }

  .single-reference {
    width: 40%;
    text-align: left;
    font-size: 20px;
    min-height: 100%;
    line-height: 1.5;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .references-text-container {
    width: 80vw;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .book-titles {
    font-size: 20px;
  }

  .popup-inner {
    width: 60vw;
  }
}

@media (max-width: 740px) {
  .books-container {
    flex-direction: column-reverse;
    height: 100%;
  }

  .book-img {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .book-img img {
    width: 40%;
    margin: 0;
  }

  .book-titles {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin-top: 30px;
  }

  .single-reference {
    width: 80%;
    border-bottom: 1px solid #570946;
    padding-bottom: 30px;
  }

  .single-reference:last-child {
    border: none;
  }

  .references-text-container {
    justify-content: center;
  }
}

@media (max-width: 500px) {
  .book-titles {
    font-size: 18px;
  }

  .year-book {
    min-width: 50px;
    text-align: left;
  }

  .popup-inner {
    width: 85vw;
    font-size: 16px;
  }
}

/*CONTACT*/

.contact  {
  display: flex;
  flex-direction: column;
}

.bully-row {
  width: 50vw;
  margin: 0 auto;
}

.pink-bully {
  width: 10%;
  margin-right: 5%;
}

.contact-text {
  width: 60vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  flex-wrap: wrap;
}

.contact-text p {
  text-align: left;
}

.contact-text h3 {
  font-weight: 100;
}

.contact-text-div {
  width: 30%;
  min-height: 100%;
  line-height: 1.5;
}

.contact-text-div a {
  text-decoration: none;
  color: #570946;
}

.contact-text-div h3 {
  font-size: 35px;
}

.contact-text-div h5 {
  font-size: 25px;
  margin-bottom: 0;
}

/*Impressum Untercomponent*/

.datenschutz-heading {
  font-size: 25px;
  margin-bottom: 40px;
}

.datenschutz-paragraph {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.5;
}

.datenschutz-paragraph-last {
  margin-bottom: 150px;
}

.contact-text-impressum {
  justify-content: center;
}

/*Media Queries Contact*/

@media (max-width: 1300px) {
  .contact-text {
    width: 75vw;
  }
}

@media (max-width: 1150px) {
  .contact-text {
    font-size: 18px;
  }

  .contact-text-div h3 {
    font-size: 32px;
  }
  
  .contact-text-div h5 {
    font-size: 22px;
  }
}

@media (max-width: 950px) {
  .contact-text-div {
    width: 40%;
  }

  .bully-row {
    width: 70vw;
    margin: 0 auto;
  }
  
  .pink-bully {
    width: 10%;
    margin-right: 5%;
  }
}

@media (max-width: 650px) {
  .contact-text-div {
    width: 70%;
    margin: 0 auto;
    min-height: unset;
    margin-bottom: 50px;
  }

  .contact-text p {
    text-align: center;
  }

  .bully-row {
    width: 90vw;
    margin: 0 auto;
  }
}

/*MEDIA QUERIES GENERAL*/

/*General Resize*/

@media (min-width: 1351px) and (max-width: 1550px) {
  nav {
    font-size: 28px;
  }
}

@media (min-width: 1226px) and (max-width: 1350px) {
  nav {
    font-size: 26px;
  }

  .box-title {
    font-size: 20px;
  }

  .box-text {
    font-size: 16px;
  }
}

@media (min-width: 1151px) and (max-width: 1225px) {
  nav {
    font-size: 24px;
  }

  .box-title {
    font-size: 18px;
  }

  .box-text {
    font-size: 16px;
  }
}

@media (min-width: 1026px) and (max-width: 1150px) {
  nav {
    font-size: 22px;
  }
}

@media (min-width: 926px) and (max-width: 1025px) {
  nav {
    font-size: 19px;
  }
}

@media (min-width: 801px) and (max-width: 925px) {
  nav {
    font-size: 17px;
  }
}

@media (max-width: 800px) {
  .header {
    flex-direction: column;
    min-height: 150px;
    align-items: center;
    justify-content: center;
  }

  .header-left,
  .header-right {
    height: 50%;
    width: 100vw;
  }

  .header-left {
    justify-content: center;
    height: calc(50%-20px);
    margin-top: 20px;
  }

  nav {
    width: 80vw;
    font-size: 20px;
    margin-top: 15px;
  }

  .logo {
    width: 25%;
    margin: unset;
    min-width: unset;
  }

  .logo-bully {
    width: 19%;
    margin-left: -2%;
    margin-top: -4%;
    min-width: 70px;
    display: none;
  }
}

/*Logo Resize*/

@media (min-width: 951px) and (max-width: 1250px) {
  .logo {
    width: 45%;
  }
  .logo-bully {
    width: 22%;
  }
}

@media (min-width: 801px) and (max-width: 950px) {
  .logo {
    width: 55%;
  }
  .logo-bully {
    width: 24%;
  }
}

@media (max-width: 700px) {
  .logo {
    width: 250px;
    margin-top: 40px;
  }
}